/**
 * elements & structure (layout) 單一元素或結構外框之類的，專案變大時，以資料夾分出多個檔案
 */
#app {
  width: 100%;
  margin: 32px auto;
}

.container {
  display: grid;
  grid-template-columns: repeat(8, $grid-col);
  grid-auto-rows: $grid-row;
  justify-content: center;
}

.tag {
  font-size: $font-size-m;
  line-height: $line-height-m;
  font-weight: 900;
  color: #fff;
  background-color: #000;
  padding: 1px 10px;
}

.title {
  font-size: $font-size-xl;
  line-height: $font-size-xl;
  font-weight: 900;
  z-index: 5;

  &--shadow {
    position: relative;

    &::before,
    &::after {
      content: attr(data-text);
      position: absolute;
      left: 0;
      overflow: hidden;
      writing-mode: inherit;
    }

    &::before {
      top: -16px;
      height: 22px;
      color: $color-text-light;
    }
    &::after {
      top: -25px;
      height: 15px;
      color: $color-text-lighter;
    }
  }
}

.subtitle {
  font-size: $font-size-l;
  line-height: $line-height-l;
  font-style: italic;
  font-weight: 700;
  z-index: 2;
}

.serial {
  font-size: $font-size-xl;
  line-height: $font-size-xl;
  font-weight: 900;
  z-index: 2;
}

.figure {
  // 使圖片垂直置中
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 100%;
  height: auto;

  img {
    width: inherit;
    height: inherit;
  }

  &--click {
    img {
      transform: scale(1);
      transition: all 0.8s;

      &:hover {
        transform: scale(1.1);
      }
    }
  }
}

.frame {
  align-self: center;
  justify-self: center;
  min-width: $grid-col;
  min-height: $grid-row;
  border: 20px solid $color-primary;
  z-index: -2;
}

.board {
  align-self: center;
  justify-self: center;
  min-width: $grid-col;
  min-height: $grid-row;
  background-color: $color-primary;
  z-index: -1;

  &--linear {
    z-index: 1;
    background:
      repeating-linear-gradient(
        135deg,
        $color-primary,
        $color-primary 11.5px,
        transparent 11.5px,
        transparent 27px,
        $color-primary 27px,
        $color-primary 35.5px
      );
  }
}

.main {
  &__container {
    grid-template-areas:
      "😍 😍 😍 😍 😍 😍 😍 😍"
      "❤️ ❤️ ❤️ ❤️ ❤️ 💚 💚 💚"
      "❤️ ❤️ ❤️ ❤️ ❤️ 💚 💚 💚"
      "❤️ ❤️ ❤️ ❤️ ❤️ 💚 💚 💚"
      "❤️ ❤️ ❤️ ❤️ ❤️ 💚 💚 💚"
      "❤️ ❤️ ❤️ ❤️ ❤️ 💚 💚 💚"
      "❤️ ❤️ ❤️ ❤️ ❤️ 💙 💙 💙"
      "💜 💜 💜 💜 💜 💙 💙 💙"
      "💜 💜 💜 💜 💜 💙 💙 💙"
      "💜 💜 💜 💜 💜 💙 💙 💙"
      "💜 💜 💜 💜 💜 💙 💙 💙"
      "💛 💛 💛 ..  .. 💙 💙 💙"
      "💛 💛 💛 ..  .. 💙 💙 💙"
      "💛 💛 💛 💗 💗 💗 💗 💗"
      "💛 💛 💛 💗 💗 💗 💗 💗"
      "🖤 🖤 🖤 💗 💗 💗 💗 💗"
      "🖤 🖤 🖤 💗 💗 💗 💗 💗"
      "🖤 🖤 🖤 💗 💗 💗 💗 💗"
      "🖤 🖤 🖤 💗 💗 💗 💗 💗"
      "🖤 🖤 🖤 .. ..  .. ..  ..";
  }

  &__header {
    grid-area: 😍;
    text-align: center;
  }

  &__title {
    font-size: $font-size-xxl;
    line-height: $font-size-xxl;
    margin-top: 14px;
    font-weight: 900;
  }

  &__item-1 {
    grid-area: ❤️;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(6, 1fr);

    .title {
      grid-column: 1 / -1;
      grid-row: 1 / span 2;
      align-self: center;
      margin-top: 18px;
    }

    .subtitle {
      grid-column: 5 / span 1;
      grid-row: 5 / -1;
      writing-mode: vertical-lr;
    }

    .serial {
      grid-column: 1 / span 1;
      grid-row: 6 / span 1;
    }

    .figure {
      grid-column: 1 / span 4;
      grid-row: 2 / span 4;
    }

    .frame {
      grid-column: 3 / span 3;
      grid-row: 1 / span 3;
      width: $grid-col * 2;
      height: $grid-row * 2;
    }

    .board {
      grid-column: 1 / span 3;
      grid-row: 5 / span 2;
      width: $grid-col * 2;
      height: $grid-row;
    }
  }

  &__item-2 {
    grid-area: 💚;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(5, 1fr);

    .title {
      grid-column: 2 / -1;
      grid-row: 5 / span 1;
      margin-top: 20px;
    }

    .subtitle {
      grid-column: 1 / -1;
      grid-row: 2 / span 1;
      align-self: end;
    }

    .serial {
      grid-column: 3 / span 1;
      grid-row: 2 / span 1;
      justify-self: end;
    }

    .figure {
      grid-column: 1 / span 2;
      grid-row: 3 / span 2;
    }

    .frame {
      grid-column: 2 /  span 2;
      grid-row: 2 / span 2;
      width: $grid-col * 2;
      height: $grid-row * 2;
    }

    .board {
      grid-column: 1 /  span 3;
      grid-row: 2 /  span 3;
      width: $grid-col * 2;
      height: $grid-row * 2;
    }
  }

  &__item-3 {
    grid-area: 💜;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(4, 1fr);

    .title {
      grid-column: 1 / span 4;
      grid-row: 1 / span 1;
      justify-self: end;
      align-self: end;
    }

    .subtitle {
      grid-column: 1 / span 2;
      grid-row: 4 / span 1;
      justify-self: end;
    }

    .serial {
      grid-column: 5 / span 1;
      grid-row: 4 / span 1;
    }

    .figure {
      grid-column: 1 / span 4;
      grid-row: 2 / span 2;
    }

    .frame {
      grid-column: 3 / span 3;
      grid-row: 2 / span 3;
      width: $grid-col * 2;
      height: $grid-row * 2;
    }

    .board {
      grid-column: 1 / span 2;
      grid-row: 1 / span 3;
      width: $grid-col * 2;
      height: $grid-row * 2;
    }
  }

  &__item-4 {
    grid-area: 💙;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(7, 1fr);

    .title {
      grid-column: 1 / span 1;
      grid-row: 3 / span 3;
      writing-mode: vertical-rl;
      transform: rotate(180deg);
      align-self: start;
      justify-self: end;

      &::before {
        top: 0;
        right: -16px;
        width: 22px;
        height: auto;
      }
      &::after {
        top: 0;
        right: -25px;
        width: 15px;
        height: auto;
      }
    }

    .subtitle {
      grid-column: 3 / span 1;
      grid-row: 1 / span 2;
      align-self: end;
      justify-self: center;
      writing-mode: vertical-rl;
      margin-bottom: 5px;
    }

    .serial {
      grid-column: 1 / span 1;
      grid-row: 6 / span 1;
      align-self: end;
      justify-self: end;
    }

    .figure {
      grid-column: 2 / span 2;
      grid-row: 3 / span 4;

      img {
        width: auto;
        height: 100%;
      }
    }

    .frame {
      grid-column: 1 / span 3;
      grid-row: 2 / span 2;
      width: $grid-col * 2;
      height: $grid-row * 2;
    }

    .board {
      grid-column: 2 / span 2;
      grid-row: 6 / span 2;
      width: $grid-col * 2;
      height: $grid-row;
    }
  }

  &__item-5 {
    grid-area: 💛;
    grid-column-end: span 6;
    grid-row-end: span 4;

    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(4, 1fr);

    .title {
      grid-column: 1 / span 3;
      grid-row: 2 / span 1;
      align-self: end;
    }

    .subtitle {
      grid-column: 5 / -1;
      grid-row: 2 / span 1;
      margin-left: 3px;
    }

    .serial {
      grid-column: 2 / span 1;
      grid-row: 3 / span 1;
      align-self: end;
    }

    .figure {
      grid-column: 3 / span 2;
      grid-row: 2 / span 2;
      img {
        width: auto;
        height: 100%;
        transform: scaleX(-1) scale(1);

        &:hover {
          transform: scaleX(-1) scale(1.1);
        }
      }
    }

    .frame {
      grid-column: 2 / span 2;
      grid-row: 3 / span 2;
      width: $grid-col * 2;
      height: $grid-row;
    }

    .board {
      grid-column: 1 / span 3;
      grid-row: 1 / span 2;
      width: $grid-col * 2;
      height: $grid-row;
    }
  }

  &__item-6 {
    grid-area: 💗;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(6, 1fr);

    .title {
      grid-column: 1 / -1;
      grid-row: 1 / span 1;
      align-self: end;
      justify-self: end;
    }

    .subtitle {
      grid-column: 2 / -1;
      grid-row: 6 / span 1;
      justify-self: center;
    }

    .serial {
      grid-column: 1 / span 1;
      grid-row: 3 / span 1;
      align-self: end;
      justify-self: end;
    }

    .figure {
      grid-column: 2 / span 4;
      grid-row: 2 / span 4;
    }

    .frame {
      grid-column: 2 / span 4;
      grid-row: 4 / span 3;
      width: $grid-col * 4;
      height: $grid-row * 2;
    }

    .board {
      grid-column: 1 / span 3;
      grid-row: 4 / span 2;
      width: $grid-col * 2;
      height: $grid-row * 2;
    }
  }

  &__item-7 {
    grid-area: 🖤;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(5, 1fr);

    .title {
      grid-column: 3 / span 1;
      grid-row: 2 / span 3;
      writing-mode: vertical-rl;
      justify-self: start;

      &::before {
        top: 0;
        right: -16px;
        width: 22px;
        height: auto;
      }
      &::after {
        top: 0;
        right: -25px;
        width: 15px;
        height: auto;
      }
    }

    .subtitle {
      grid-column: 1 / -1;
      grid-row: 1 / span 1;
      align-self: end;
    }

    .serial {
      grid-column: 3 / span 1;
      grid-row: 5 / span 1;
      align-self: end;
      justify-self: end;
    }

    .figure {
      grid-column: 1 / span 2;
      grid-row: 2 / span 4;
      img {
        width: auto;
        height: 100%;
      }
    }

    .frame {
      grid-column: 1 / span 3;
      grid-row: 1 / span 5;
      width: $grid-col * 2;
      height: $grid-row * 4;
    }

    .board {
      grid-column: 2 / span 2;
      grid-row: 4 / span 2;
      width: $grid-col * 2;
      height: $grid-row * 2;
    }
  }
}

.product {

  &__return.container {
    grid-template-rows: repeat(1, $grid-row);

    .icon {
      grid-column: 1 / span 1;
      grid-row: 1 / span 1;
      align-self: center;
      justify-self: end;
      font-size: $font-size-l;
      color: $color-text;
      margin-right: 8px;
    }
    .tag {
      grid-column: 2 / span 3;
      grid-row: 1 / span 1;
      align-self: center;
      justify-self: start;
    }

    a {
      opacity: 1;
      transition: all 0.3s;

      &:hover {
        opacity: 0.6;
      }
    }
  }

  &__header.container {
    grid-template-rows: repeat(6, $grid-row);
    margin-bottom: $grid-row;

    .figure {
      grid-column: 2 / span 6;
      grid-row: 2 / span 4;
      img {
        position: relative;
        top: 20%;
      }
    }

    .title-1 {
      grid-column: 2 / span 6;
      grid-row: 1 / span 1;
      justify-self: end;
      align-self: end;
    }
    .title-2 {
      grid-column: 8 / span 1;
      grid-row: 2 / span 4;
      writing-mode: vertical-lr;
    }
    .title-3 {
      grid-column: 2 / span 2;
      grid-row: 6 / span 1;
      justify-self: start;
      align-self: start;
      transform: rotate(180deg);
    }
    .serial {
      grid-column: 1 / span 1;
      grid-row: 5 / span 1;
      writing-mode: vertical-lr;
      transform: rotate(180deg);
    }
    .frame {
      grid-column: 4 / span 5;
      grid-row: 4 / span 3;
      width: $grid-col * 4;
      height: $grid-row * 2;
    }
    .board {
      grid-column: 1 / span 3;
      grid-row: 1 / span 3;
      width: $grid-col * 2;
      height: $grid-row * 2;
    }
  }

  &__content.container {
    grid-template-rows: repeat(4, $grid-row);
    margin-bottom: $grid-row;

    hgroup {
      grid-column: 3 / span 5;
      grid-row: 1 / span 1;

      .title {
        margin-bottom: 4px;
      }

      .subtitle-1 {
        font-style: initial;
        font-weight: 900;
      }
    }

    .subtitle-2 {
      grid-column: 2 / span 1;
      grid-row: 1 / -1;
      writing-mode: vertical-lr;
      margin-left: 4px;
    }

    article {
      grid-column: 3 / span 5;
      grid-row: 2 / -1;
    }
  }

  &__footer.container {
    grid-template-rows: repeat(7, $grid-row);

    .figure-1 {
      grid-column: 4 / span 4;
      grid-row: 3 / span 4;
    }
    .figure-2 {
      grid-column: 2 / span 3;
      grid-row: 1 / span 3;
      img {
        height: 100%;
        width: auto;
      }
    }

    .frame {
      grid-column: 1 / span 5;
      grid-row: 2 / span 3;
      width: $grid-col * 4;
      height: $grid-row * 2;
    }
    .board-1 {
      grid-column: 6 / span 3;
      grid-row: 2 / span 2;
      width: $grid-col * 2;
      height: $grid-row * 2;
    }
    .board-2 {
      grid-column: 2 / span 3;
      grid-row: 6 / span 2;
      width: $grid-col * 2;
      height: $grid-row * 1;
    }
  }
}

.author-footer {
  text-align: center;
  color: #bbb;
  margin: 1rem auto;

  &-pen {
    margin-right: 0.25rem;
  }
  &-link {
    color: #bbb;
    margin-left: 0.25rem;

    &:hover {
      color: $color-primary;
    }
  }
}
