$font-size: 16px; // 16px
$font-size-m: 20px; // 20px
$font-size-l: 24px; // 24px
$font-size-xl: 48px; // 48px
$font-size-xxl: 72px; // 72px

$line-height-m: 24px;
$line-height-l: 28px;
$line-height-xl: 56px;
$line-height-xxl: 85px;

$color-text: #000;
$color-text-light: rgba(0,0,0, 0.3);
$color-text-lighter: rgba(0,0,0, 0.1);

$color-primary: #50E3C2;

$color-bg: #fff;

$grid-col: 120px;
$grid-row: 100px;
